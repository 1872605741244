<template>
  <PageContainer>
    <div class="organization-item-container">
      <v-progress-linear v-if="isLoading" indeterminate />
      <HealthcareServiceInfo v-for="item in healthcareServices" :key="item.id" :item="item" @edit="edit" />
    </div>
  </PageContainer>
</template>
<script>
import { mapActions, mapState } from "pinia";

import PageContainer from "@/components/uikit/PageContainer.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { routesEnum } from "@/types/Routes.enum";
import { calendarValues } from "@/utils/constants";
import { scrollToTop } from "@/utils/scrollToBottom";
import HealthcareServiceInfo from "@/views/Owner/Organizations/HealthcareServices/HealthcareServiceInfo.vue";

export default {
  name: "HealthcareServicesIndex",
  data() {
    return {
      isLoading: false,
      calendarValue: "week",
      currentDate: new Date(),
    };
  },
  components: { HealthcareServiceInfo, PageContainer },
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(useHealthcareServiceStore, ["healthcareServices"]),
    calendarValues() {
      return calendarValues;
    },
  },
  methods: {
    ...mapActions(useHealthcareServiceStore, ["setHealthcareServices", "getHealthcareServices", "setForm"]),
    add() {
      this.setForm({});
      this.$router.push({ name: routesEnum.addOrganization });
    },
    edit(item) {
      this.setForm(item);
      this.$router.push({
        name: routesEnum.addHealthcareService,
        params: { id: item.id, organizationId: this.$route.params.organizationId },
      });
    },
    onMoreClick(e) {
      console.log("on more click", e);
    },
    onCalendarDateClick(e) {
      console.log("on onCalendarDateClick", e);
    },
    changeCalendarValue(e) {
      this.calendarValue = e;
      // this.$router.push({
      //   name: routesEnum.practitionerSchedule,
      //   query: { calendarValue: e, currentDate: this.currentDate },
      // });
      // this.getScheduledAppointments();
    },
    getEventColor(event) {
      return event.color;
    },
    selectCalendarTime(value) {
      console.log(value);
    },
    selectCalendarEvent(value) {
      console.log(value);
    },
  },
  async mounted() {
    scrollToTop();
    this.isLoading = true;
    await this.getHealthcareServices(this.$route.params.organizationId);
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.organization-item-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}
</style>
