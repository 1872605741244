<template>
  <v-card class="organization-item">
    <v-card-title class="justify-start pointer" @click="$emit('view', item)">
      <img v-if="item.logoUrl" :alt="item.logoUrl" :src="item.logoUrl" class="d-block mx-4" width="50px" />
      {{ item.name }}
    </v-card-title>
    <v-row class="pa-3">
      <v-col cols="12" lg="4">
        <span class="heading-6"> Broad category of service being performed </span>
        <ul>
          <li v-for="item in item.categoryCoding" :key="item.code">{{ item.display || "" }}</li>
        </ul>
      </v-col>
      <v-col cols="12" lg="4">
        <span class="heading-6"> Type of service that may be delivered </span>
        <ul>
          <li v-for="item in item.typeCoding" :key="item.code">{{ item.display || "" }}</li>
        </ul>
      </v-col>
      <v-col cols="12" lg="4">
        <span class="heading-6"> Specialties handled by the HealthcareService </span>
        <ul>
          <li v-for="item in item.specialtyCoding" :key="item.code">{{ item.display || "" }}</li>
        </ul>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card-actions class="d-flex justify-end">
      <IconicButton left-icon="mdi-pencil-outline" size="small" text="Modify" @onClick="$emit('edit', item)" />
    </v-card-actions>
  </v-card>
</template>
<script>
import IconicButton from "@/components/uikit/IconicButton.vue";

export default {
  name: "HealthcareServiceInfo",
  components: { IconicButton },
  props: {
    hideViewSchedule: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
